.App {
  text-align: center;
  background-color: #282c34;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-box {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  padding-left: 50px;
  padding-top: 50px;
  justify-content: flex-start;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tag {
  background-color: grey;
  color: white;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
}

.questionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.questionsWrapper .questions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.questionsWrapper .tags {
  display: flex;
  flex-direction: column;
}

.gridWrapper {
 height: 100vh;
 overflow: auto;
 display: flex;
}

.tile {
  border: 1px solid white;
}

